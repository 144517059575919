import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import {
  desktop,
  tablet,
  laptop_small,
  ColorService,
  getFontFamily,
} from 'services';

import { Icon, IconsNames } from 'components/atoms';

export const Header = memo(() => (
  <HeaderWrapper>
    <HeaderStyled>
      <Logo>
        <Link to="/">
          <IconLogo>
            <Icon name={IconsNames.logo_white} />
          </IconLogo>
        </Link>
        <DesktopBox>
          <Hint>Никаких записей курсов — только живое общение</Hint>
        </DesktopBox>
      </Logo>
      <Phone>
        <IconPhone>
          <Icon name={IconsNames.phone} />
        </IconPhone>
        <Link to="tel:+375291055959">+375 (29) 105-59-59</Link>
      </Phone>
    </HeaderStyled>
  </HeaderWrapper>
));

const HeaderWrapper = styled.header`
  width: 100%;
  box-sizing: border-box;
  border-top: 2px solid ${ColorService.YELLOW};
  background-color: ${ColorService.TRANSPARENT};
  padding: 0 0 10px;
  position: absolute;

  ${tablet} {
    padding: 0 0 15px;
    border-top: 4px solid ${ColorService.YELLOW};
  }
`;

const HeaderStyled = styled.div`
  max-width: 1110px;
  margin: 15px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;

  ${tablet} {
    justify-content: space-between;
    padding: 0 40px;
    margin: 20px auto 0;
  }

  ${desktop} {
    overflow: initial;
  }

  ${laptop_small} {
    padding: 0;
    margin: 62px auto 0;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${tablet} {
    justify-content: flex-start;
    gap: 24px;
    width: 50%;
  }

  ${desktop} {
    gap: 16px;
    width: unset;
    margin-left: -16px;
    margin-top: 8px;
  }
`;

const IconLogo = styled.div`
  width: 194px;
  height: 20px;
  position: relative;

  ${desktop} {
    width: 268px;
    height: 28px;
    margin-right: 16px;
    position: relative;
    z-index: 6;
    cursor: pointer;
  }
`;

const DesktopBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Hint = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-family: ${getFontFamily()};
  margin-left: 12px;
  color: #acb1b6;
  display: none;
  ${desktop} {
    display: block;
  }
`;

const Phone = styled.div`
  width: 177px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 14px;
  line-height: 21px;
  font-family: ${getFontFamily('medium')};
  background: transparent;
  background: ${ColorService.YELLOW};
  border: 1px solid ${ColorService.YELLOW};
  position: relative;
  z-index: 6;
  display: none;
  color: ${ColorService.BLACK};

  ${tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    transition: 0.6s;
    background: ${ColorService.YELLOW_LEMON};
    border: 1px solid ${ColorService.YELLOW_LEMON};
    color: ${ColorService.BLACK};

    a,
    a:hover {
      color: ${ColorService.BLACK} !important;
    }
  }

  &:active {
    transition: 0.6s;
    background: ${ColorService.MUSTARD};
    border: 1px solid ${ColorService.MUSTARD};
  }
`;

const IconPhone = styled.div`
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 2px;
`;
